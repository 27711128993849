html,
body,
#root,
.App {
  height: 100%;
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  appearance: none;
  padding-right: 30px !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  .red{
    @apply bg-red-100;  
  }

  .yellow{
    @apply bg-amber-100;  
  }

  .slate{
    @apply bg-slate-100;  
  }

  .gray{
    @apply bg-gray-100;  
  }

  .green{
    @apply bg-emerald-100;  
  }

  .btn.red{
    @apply bg-red-100 hover:bg-red-200;  
  }

  .btn.yellow{
    @apply bg-amber-100 hover:bg-amber-200;  
  }

  .btn.slate{
    @apply bg-slate-100 hover:bg-slate-200;  
  }

  .btn.gray{
    @apply bg-gray-100 hover:bg-gray-200;  
  }

  .btn.green{
    @apply bg-emerald-100 hover:bg-emerald-200;  
  }

  .btn {
    @apply transition-all inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 hover:transition-all focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2;
  }

  .input {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
  }

  .input:disabled{
    @apply bg-gray-700;
  }

  .table_row:nth-child(even) {
    @apply bg-slate-50;
  }

  .table_row p, .project-description p {
    @apply mb-2;
  }

  .react-tooltip.tooltip{
    @apply bg-slate-600;
  }
}
